<template>
  <div>
   <!--  <v-text-field
      v-model="search"
      counter
      label="Cod Postal | Población | Provincia"
      outlined
      dense
      autocomplete="off"
    ></v-text-field>     -->
    <v-autocomplete
      label="Cod Postal | Población | Provincia"
      no-data-text="No se han encontrado resultados"
      hide-no-data
      hide-selected
      filled
      chips
      small-chips
      :items="items"
      item-value="zico_id"
      :search-input.sync="search"
      :loading="isLoading"
      outlined
      dense
      v-model="selected.read_idcp"
      no-filter
      autocomplete="OFF"
      @change="setNewZipId(selected)"
      :menu-props="{ closeOnContentClick: true }"
      clearable
    >
      <template v-slot:selection="data">
        <!-- <v-chip
          :input-value="data.selected"
          label
          small
          color="primary"
          @click="data.select"
        > -->
        <span class="body-2">
          {{ data.item.salida }}
        </span>
        <!--   </v-chip> -->
      </template>
      <template v-slot:item="{ item }">
        <!--  <v-list-item-content>
          <v-list-item-title v-text="item.salida"></v-list-item-title>
        </v-list-item-content> -->
        <td class="body-2">{{ item.salida }}</td>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { debounce, cloneDeep } from "lodash";

export default {
  name: "ZipCodesSelector",
  props: ["label", "selected", "requerido", "emitirRegistro", "zipCodes"],
  data: () => ({
    isLoading: false,
    search: null,
    entries: [],
    miZipId: null,
    staticZip: null,
  }),
  methods: {
    ...mapActions("records", ["setZipCodesSearch"]),
    busquedaAtrasada(val) {
      if (val === null || val.length < 3) return;
      this.isLoading = true;
      this.entries = filtrarZip(val, this.staticZip);
      this.isLoading = false;
    },
    /* setear un nuevo zipcode */
    setNewZipId(tmp) {
      // const salida = !this.miZipId ? null : this.miZipId;
      if (!tmp.read_idcp) {
        return;
      }
      //si en el props tenemos emitirRegistro emitimos todo el registro (para guias)
      if (this.emitirRegistro === true) {
        const salida = this.entries.find((e) => e.zico_id === tmp.read_idcp);
        this.$emit("emitSeleccion", salida);
      } else {
        this.$emit("emitSeleccion", tmp.read_idcp);
      }
    },
  },
  computed: {
    items() {
      if (!this.entries) {
        return [];
      }

      return this.entries.map((entry) => {
        const salida =
          entry.zico_code +
          " - " +
          entry.zico_town +
          " (" +
          entry.zico_province +
          ")";

        return Object.assign({}, entry, { salida });
      });
      this.isLoading = false;
    },
  },
  watch: {
    search: function(newVal) {
      if (!newVal) {
        return;
      }
      this.busquedaAtrasada(newVal);
    },
  },
  mounted() {
    if (this.selected.read_idcp !== null) {
      this.entries.push(this.selected.zipdata);
    }
    this.staticZip = cloneDeep(this.zipCodes);
  },
};

/* funcion para filtrar los codPostales */
const filtrarZip = (txt, zipCodes, limite = 50) => {
  const f = txt.toUpperCase();
  let c = 0;
  let salida = [];
  for (const z of zipCodes) {
    if (
      z.zico_code.includes(f) ||
      z.zico_province.includes(f) ||
      z.zico_town.includes(f)
    ) {
      salida.push(z);
      c++;
      if (c >= limite) {
        break;
      }
    }
  }  
  return salida;
};
</script>

<style scoped></style>
